<template>
  <div class="ev2-box console-devs hscroll">
    <div class="header">
      <div class="hcol1">
        <div class="title">Análise do time</div>
        <div class="subtitle">Resumo dos colaboladores e demandas</div>
      </div>
      <div class="hcol2">
        <erp-input v-model="busca" class="sl-input input-sm" placeholder="Buscar" :before="[{icon: 'search', handler () {}}]" />
        <erp-select v-model="buscaTipo" :options="buscaTipoOpts" class="sl-input input-sm m-l-sm" />
      </div>
    </div>
    <div class="body m-t">
      <u-table
          ref="table"
          color="primary"
          :data="table.serverData"
          :columns="table.columns"
          :visible-columns="visibledColumns"
          :filter="table.busca"
          row-key="id"
          :pagination.sync="table.serverPagination"
          @request="request"
          selection="multiple"
          :selected.sync="table.selected"
          :loading="table.loading"
          class="ev2-dash-table time-lista"
          :rows-per-page-options="[20, 50, 100]"
          :hide-no-results-label="true"
      >
        <u-tr slot="header" slot-scope="props">
          <u-th v-for="col in props.cols" :key="col.name" :props="props">
            <template>
              {{ col.label }}
            </template>
            <div v-if="col.name === 'options'" class="text-center">
            </div>
          </u-th>
        </u-tr>

        <u-tr class="colaborador" slot="body" slot-scope="props" :props="props">
          <u-td class="td-limit" style="cursor: default; min-width: 150px" key="person" :props="props">
            <div class="flex items-center imagem no-select">
              <div class="app-person-avatar m-r-sm flex justify-center full-width">
                <img v-if="props.row.person && props.row.person.photo" :src="props.row.person.photo">
                <div v-else class="img-fake" style="background-color: #399B9F">{{ firstLettersName(props.row.person.name) }}</div>
              </div>
              <div class="m-l-sm flex items-center column">
                <div>
                  <div>{{props.row.person.name}}</div>
                  <div class="worker" v-if="props.row.papeis.worker && props.row.papeis.worker.office">{{props.row.papeis.worker.office.name}}</div>
                </div>
              </div>
            </div>
          </u-td>
          <u-td style="width: 100px" class="" key="actualTask" :props="props">
            <div v-if="props.row.tarefasEmAndamento && props.row.tarefasEmAndamento.length" @click="showTasklist(props.row.tarefasEmAndamento)" class="cursor-pointer">
              {{ props.row.tarefasEmAndamento?.length }}
            </div>
            <div v-else style="cursor: default">0</div>
          </u-td>
          <u-td class="" key="tasks" :props="props">
            <div v-if="props.row.tarefas && props.row.tarefas.length" @click="showTasklist(props.row.tarefas)" class="cursor-pointer">
              {{ props.row.tarefas?.length }}
            </div>
            <div v-else style="cursor: default">0</div>
          </u-td>
          <u-td class="" key="dueTasks" :props="props">
            <div v-if="props.row.tarefasAtrasadas && props.row.tarefasAtrasadas.length" @click="showTasklist(props.row.tarefasAtrasadas)" class="cursor-pointer text-negative">
              {{ props.row.tarefasAtrasadas?.length }}
            </div>
            <div v-else style="cursor: default">0</div>
          </u-td>
          <u-td style="width: 80px; max-width: 80px" class="" key="hours" :props="props">
            <div v-if="props.row.horasTrabalhadas">
            {{ tratarHorasTrabalhadas(props.row.horasTrabalhadas) }}
            </div>
            <div v-else>-</div>
          </u-td>
          <u-td class="text-right" key="options" :props="props">
            <div style="display: none" class="cursor-pointer">
              <div class="text-right no-select">Ações <i class="fa fa-chevron-down text-grey-5 font-10" /></div>
              <u-popover class="window-context-menu menu-profile min" anchor="bottom right" self="top right">
                <ul>
                  <menu-options-item close label="Abrir" @click="$emit('abrir')" />
                </ul>
              </u-popover>
            </div>
          </u-td>
        </u-tr>
      </u-table>
    </div>
<!--    <div class="footer">
      <div class="text-center">
        <u-btn class="f-btn" no-caps label="Novo Registro" />
      </div>
    </div>-->
  </div>
</template>

<script>
import ErpInput from "@/plugins/uloc-erp/components/form/input"
import ErpSelect from "@/plugins/uloc-erp/components/form/select"
import tableColumnsCache from "@/utils/tableColumnsCache"
import {UTable, UTr, UTh, UTd, date, UPopover} from "uloc-vue"
import {datePtToEn} from "@/utils/date"
import MenuOptionsItem from "components/layout/context-menu/context-window-options-item.vue"
import {teamStats} from "@/domain/gerencia/stats/services"
import taskListWindow from '@/components/console/components/suporte/dashboard/window/taskListWindow'

let filters = {
  id: null,
}

const listName = 'table.console.dash.team'
const listStorage = tableColumnsCache(listName, [
  {label: 'Colaborador', name: 'person', active: true, sortable: true, ordering: 1},
  {label: 'Trabalhando', name: 'actualTask', active: true, sortable: true, ordering: 2},
  {label: 'Tarefas', name: 'tasks', active: true, sortable: true, ordering: 3},
  {label: 'Atrasadas', name: 'dueTasks', active: true, sortable: true, ordering: 4},
  {label: 'Horas mês', name: 'hours', active: true, sortable: true, ordering: 5},
], 1)
export default {
  name: "ConsoleDashColaboradores",
  components: {
    MenuOptionsItem,
    ErpSelect,
    ErpInput,
    UTable, UTr, UTh, UTd, UPopover
  },
  data () {
    const filtros = JSON.parse(JSON.stringify(filters))
    return {
      busca: null,
      buscaTipo: 'ativos',
      buscaTipoOpts: [
        {label: 'Ativos', value: 'ativos'},
        {label: 'Com sobrecarga', value: 'sobrecarga'},
        {label: 'Sem atribuição', value: 'semTarefa'},
      ],
      listStorage: listStorage,
      table: {
        busca: '',
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 0, // specifying this determines pagination is server-side
          rowsPerPage: 20
        },
        columns: listStorage.map(o => {
          return {
            ...o,
            field: o.name,
            align: 'left'
          }
        }).concat([
          {
            name: 'options',
            required: true,
            label: '',
            field: 'options',
            sortable: false
          }
        ]),
        filter: '',
        filters: filtros,
        selected: [],
        loading: false
      }
    }
  },
  mounted() {
    this.load()
  },
  computed: {
    visibledColumns() {
      return this.listStorage.filter(item => item.active).map(item => item.name)
    }
  },
  methods: {
    load () {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.busca
      })
    },
    request({pagination, filter}) {

      console.log('XXX')
      //this.table.serverPagination.rowsNumber = 0

      let data1, data2
      let extraFilters = []

      if (this.table.filters.data1) {
        if (this.table.filters.data1.length < 10 || this.table.filters.data2.length < 10) {
          alert('Digite a data inicial e data final corretamente para o filtro de entrada.')
          return
        }

        data1 = datePtToEn(this.table.filters.data1)
        data2 = datePtToEn(this.table.filters.data2)
        if (!date.isValid(data1) || !date.isValid(data2)) {
          alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
          return
        }
        extraFilters.push(`&data1=${data1}&data2=${data2}`)
      }

      this.table.filters.tipoData && extraFilters.push(`&typeDate=${this.table.filters.tipoData}`)

      this.table.filters.id && extraFilters.push(`&id=${this.table.filters.id}`)
      this.table.filters.search && extraFilters.push(`&search=${this.table.filters.search}`)

      if (Array.isArray(this.table.filters.status) && this.table.filters.status.length > 0) {
        extraFilters.push(`&status=${this.table.filters.status.join(',')}`)
      }

      this.table.filters.pessoa && extraFilters.push(`&pessoa=${this.table.filters.pessoa.id || this.table.filters.pessoa}`)
      this.table.filters.status && extraFilters.push(`&status=${this.table.filters.status}`)

      /*if (exportar) {
        extraFilters.push(`&export=${exportar}`)
      }*/

      this.table.loading = true
      let _filter = this.filtros
      let filtros = encodeURI(`&sortBy=${pagination.sortBy || 'createdAt'}&descending=${pagination.sortBy ? (pagination.descending ? 'true' : 'false') : (this.listType === 'ativos' ? 'false' : true)}&${extraFilters.join('')}`)

      console.log(filtros)

      teamStats(filtros)
          .then((response) => {
            const data = response.data
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = data.users
            this.table.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    showTasklist (list) {
      this.taskListWindow(list)
    },
    taskListWindow,
    tratarHorasTrabalhadas (time) {
      let parts = time.split(':')
      return parts[0] + ':' + parts[1]
    }
  }
}
</script>
